import {Component, ElementRef, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Utils} from './core/services/tools/utils';
import {DeviceDetectorService} from 'ngx-device-detector';

export const LANGUAGE_CODE = {
  English: 'en',
  French: 'fr',
  Spanish: 'es'
}

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./app.component.scss']

})
export class AppComponent {
  private styleElement: HTMLStyleElement;
  public isMobile: boolean = false;

  constructor(
    public translate: TranslateService,
    public deviceService: DeviceDetectorService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    translate.addLangs([LANGUAGE_CODE.English]); // translate.addLangs([LANGUAGE_CODE.English, LANGUAGE_CODE.French, LANGUAGE_CODE.Spanish]);
    translate.setDefaultLang(LANGUAGE_CODE.English);

    // Set language to use
    translate.use(LANGUAGE_CODE.English);

    this.isMobile = Utils.isMobile(deviceService);
    const newStyle: string =
      (this.isMobile ? 'th.mat-header-cell, td.mat-cell, td.mat-footer-cell {border-bottom-width: 0px !important;}' : '') +
      (this.isMobile ? '.grid-container {max-height: calc(100vh - 350px) !important;}' : '') +
      (this.isMobile ? '.grid-table-container {max-height: calc(100vh - 350px) !important;}' : '');
    this.styleElement = Utils.createStyle(this.elementRef, this.renderer, newStyle);
  }
}
