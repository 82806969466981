/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {STATIC_I18N} from 'src/app/core/services/generated/translate_service/translate.constants';
import {AbstractCommonComponent} from '../../../base/abstract.common.component';
import {ImageCroppedEvent, ImageTransform, LoadedImage} from 'ngx-image-cropper';
import {OutputFormat} from 'ngx-image-cropper/lib/interfaces/cropper-options.interface';
import {AvatarService} from '../../../services/tools/avatar-service';
import {Utils} from '../../../services/tools/utils';
import {ToastService} from '../../../services/tools/toast-service';
import {ImageCropperComponent} from 'ngx-image-cropper/lib/component/image-cropper.component';
import {DeviceDetectorService} from 'ngx-device-detector';

export enum DIALOG_TYPE {
  YES_NO = 1,
  YES_NO_CRITICAL= 2,
  OK= 3,
  PICTURE_CROPPER= 4,
  YES_NO_NOTES = 5,
  YES_NO_CRITICAL_NOTES = 6

}

export interface ConfigConfigDialog {
  rounded: boolean;
  aspectRatio: number;
}

export interface ReturnedBase64Image {
  base64: string;
  format: OutputFormat;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent extends AbstractCommonComponent implements OnInit {
  @ViewChild('xxx') xxxobject: ElementRef;

  public readonly STATIC_I18N = STATIC_I18N;
  public readonly DIALOG_TYPE = DIALOG_TYPE;

  public title: string;
  public message: string = '<ul><li class="circle-checkmark"></li></ul>';
  public type: DIALOG_TYPE;
  public picture: File;
  private readonly DEFAULT_PICTURE_FORMAT: OutputFormat = 'png';
  public pictureFormat: OutputFormat = this.DEFAULT_PICTURE_FORMAT;
  public roundCropper: boolean = true;
  public aspectRatio: number = 1; //1 / 1;

  public scale: number = 1;
  public readonly SCALE_STEP: number = 0.1;
  public rotate: number = 0;
  public readonly ROTATE_STEP: number = 15;
  public transformation: ImageTransform = {};

  public cropper: ImageCropperComponent;

  public imageChangedEvent: any = '';

  public croppedImage: ReturnedBase64Image = {
    base64: null,
    format: null
  };
  public infoDescription: string;
  public errDescription: string;

  public croppedImageInfo: string;
  public isCroppedImageTooBig: boolean;
  public maxBlobSize: number = 0;
  public strMaxBlobSize: string;
  public textareaTitle: string = 'Notes';
  public textareaValue: string = '';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,
    public changeDetectorRef: ChangeDetectorRef,
    private avatarService: AvatarService,
    public deviceService: DeviceDetectorService,
    private toastService: ToastService
) {
    super(changeDetectorRef, deviceService);
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.type = data.type;
    this.picture = data.picture;
    this.maxBlobSize = data.maxBlobSize;
    this.strMaxBlobSize = Utils.getHumanFileSize(this.maxBlobSize);
    this.textareaTitle = data.textareaTitle;
    this.textareaValue = data.textareaValue;

    if (this.type == DIALOG_TYPE.PICTURE_CROPPER) {
      try {
        const parts: string[] = data.picture.name.split('.');
        let format: string = parts[parts.length - 1].toLowerCase();
        if (format == 'jpg') {
          format = 'jpeg';
        }
        this.pictureFormat = (format as OutputFormat);
        if (!this.pictureFormat) {
          this.pictureFormat = this.DEFAULT_PICTURE_FORMAT;
        }
      } catch (e) {
        console.error('Error getting picture format', e);
      }
    }
    if (this.picture) {
      this.infoDescription = 'Loading picture...';
      this.toastService.showToasterInfo(this.infoDescription);
      this.errDescription = null;
    }
    if (data.config) {
      if (data.config.rounded != null) {
        this.roundCropper = data.config.rounded;
      }
      if (data.config.rounded != null) {
        this.aspectRatio = data.config.aspectRatio;
      }
    }
  }

  ngOnInit(): void {
    this.refreshView();
  }

  public getTxtareaValue(): string {
    try {
      return (this.xxxobject && this.xxxobject.nativeElement) ? this.xxxobject.nativeElement.value : '';
    } catch (e) {
      return '';
    }
  }

  public isTxtareaEmpty(): boolean {
    return this.getTxtareaValue().trim().length == 0;
  }

  public onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close((this.type == this.DIALOG_TYPE.YES_NO_NOTES || this.type == this.DIALOG_TYPE.YES_NO_CRITICAL_NOTES) ? this.getTxtareaValue() : true);
  }

  public onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  public onApplyPicture(): void {
    // Close the dialog, return the croppe picture
    this.dialogRef.close(this.croppedImage);
  }

  public fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  public imageCropped(event: ImageCroppedEvent): void {
    let base64: string = event.base64;
    if (!base64.includes('image/' + this.pictureFormat + ';')) {
      try {
        console.warn('Picture format wrong, but it will be fixed');
        const b64Format: string = base64.split(';')[0].split('/')[1];
        base64 = base64.replace('image/' + b64Format + ';', 'image/' + this.pictureFormat + ';');
        console.warn('Fixed picture format, old: ' + b64Format + ', new: ' + this.pictureFormat);
      } catch (e) {}
    }
    this.croppedImage = {
      base64,
      format: this.pictureFormat
    };
    let strBlobSize: string = '';
    let blobSize: number = 0;
    try {
      const blob: Blob = this.avatarService.b64toBlob(this.croppedImage.base64, 'image/' + this.croppedImage.format);
      blobSize = blob.size;
      strBlobSize = ' (' + Utils.getHumanFileSize(blobSize) + ' bytes)';
    } catch (e) {}

    this.croppedImageInfo = event.width + 'px x ' + event.height + 'px' + strBlobSize;
    if (this.maxBlobSize > 0) {
      this.isCroppedImageTooBig = blobSize >= this.maxBlobSize;
    }
    if (this.isCroppedImageTooBig) {
      this.errDescription = 'Picture too big. Maximum size: ' + this.strMaxBlobSize + '. Tip: Use zoom and choose a smaller area to get smaller pictures.';
      this.toastService.showToasterError(this.errDescription);
    } else {
      this.errDescription = null;
    }
  }

  public imageLoaded(image: LoadedImage): void {
    this.infoDescription = null;
    try {
      if (!image.transformed || !image.transformed.size || image.transformed.size.width <= 1) {
        this.errDescription = 'Error loading image';
      }
    } catch (e) {
      this.errDescription = 'Error loading image';
    }
    if (this.errDescription) {
      this.toastService.showToasterError(this.errDescription);
    }
  }

  public cropperReady(): void {
    // cropper ready
  }

  public loadImageFailed(): void {
    // show message
    this.errDescription = 'Error loading image';
    this.toastService.showToasterError(this.errDescription);
  }

  public zoom(step: number): void {
    this.scale += step;
    if (this.scale < 0.3) {
      this.scale = 0.3;
    } else if (this.scale > 5) {
      this.scale = 5;
    }
    this.transformation = {
      scale: this.scale,
      rotate: this.rotate,
      flipH: this.transformation.flipH,
      flipV: this.transformation.flipV
    };
  }

  public rotation(step: number): void {
    this.rotate += step;
    this.rotate = this.rotate % 360;
    this.transformation = {
      scale: this.scale,
      rotate: this.rotate,
      flipH: this.transformation.flipH,
      flipV: this.transformation.flipV
    };
  }

  public flipH(): void {
    this.transformation = {
      scale: this.scale,
      rotate: this.rotate,
      flipH: !this.transformation.flipH,
      flipV: this.transformation.flipV
    };
  }

  public flipV(): void {
    this.transformation = {
      scale: this.scale,
      rotate: this.rotate,
      flipH: this.transformation.flipH,
      flipV: !this.transformation.flipV
    };
  }

  public resetTransformations(): void {
    this.scale = 1;
    this.rotate = 0;
    this.transformation = {
      scale: this.scale,
      rotate: this.rotate,
      flipH: false,
      flipV: false
    };
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
  constructor(public config: ConfigConfigDialog, public title: string, public message: string, public type: DIALOG_TYPE, public picture?: File, public maxBlobSize?: number, public textareaTitle?: string, public textareaValue?: string) {
  }
}
