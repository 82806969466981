/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

// import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfigService} from '../../config/app-config.service';

@Injectable()
export class HealthService {
  public readonly basePathCumService = this.appConfig.getBackendService();
  public readonly baseQueryCumService = this.basePathCumService + '/actuator/health';

  constructor(
    // private http: HttpClient,
    private appConfig: AppConfigService
  ) {
  }

/*  private checkHealthService(path: string): Observable<any> {
    try {
      return this.http.get(path)
        .pipe(
          map(data => {
            if (data && data['status']) {
              return data['status'];

            } else {
              return 'none';

            }
          }),
          catchError(payload => {
            return of(null);
          })
        )
        ;
    } catch (e) {
      return of(null);
    }
  }*/

  private showErrorMessage(data) {
    let msg = 'Status unknown';
    if (!data) {
      msg = 'no possible to connect';
    }
    else if ((data + '').toLowerCase() == 'none') {
      msg = 'no status received';
    }
    else if ((data + '').toLowerCase() == 'up') {
      msg = 'UP';
    }
    else if ((data + '').toLowerCase() == 'down') {
      msg = 'DOWN (but online)';
    }
    console.error('# Check health service CUM: ', msg);
  }

  public checkHealthCumService() {
    this.onlineCheck(this.appConfig.getBackendURL() + this.baseQueryCumService);

    /*try {
      this.checkHealthService(this.baseQueryCumService).subscribe((data) => {
          this.showErrorMessage(data);
        },
        error => {
          this.showErrorMessage(null);
        });

    } catch (e) {
      this.showErrorMessage(null);

    }*/
  }

  private onlineCheck(url): void {
    let xhr = new XMLHttpRequest();
    new Promise((resolve, reject) => {
      xhr.onload = () => {
        if (!xhr.status || xhr.status < 200 || xhr.status > 299) {
          this.showErrorMessage(null);
        }
        resolve(true);
      };
      xhr.onerror = () => {
        this.showErrorMessage(null);
        reject(false);
      };
      xhr.open('GET', url, true);
      xhr.send();
    });
  }
}
