/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

/* tslint:disable */

const I18N_FOLDER_RELATIVE_PATH: string = 'assets/i18n/';
export const I18N_DIALOG_FOLDER_RELATIVE_PATH: string = I18N_FOLDER_RELATIVE_PATH + 'dialogs/';
export const I18N_BACKEND_FOLDER_RELATIVE_PATH: string = I18N_FOLDER_RELATIVE_PATH + 'backend/';
export const I18N_FILE_EXTENSION: string = '.json';

export const MANUAL_TRANSLATION_FOLDER_RELATIVE_PATH: string = '/assets/manual_translation/';
export const MANUAL_TRANSLATION_FILE_EXTENSION: string = '.json';

export const SIDE_DATA_LIST_FOLDER_RELATIVE_PATH: string = '/assets/side_data_list/';
export const SIDE_DATA_FILE_NAME: string = 'values.json';

export const SEPARATOR_FOR_FILTER: string = '|';

export enum SIDE_DATA_LIST {
  PHONE_COUNTRY_CODES = 'phone-country-codes'
}

export enum SIDE_MANUAL_TRANSLATION {
  COUNTRIES = 'countries'
}

export const HTTP_CODE = {
  'ERROR' : {
    '_500': 500,
    '_404': 404,
    '_401': 401,
    '_400': 400
  },
  'OK': {
    '_208': 208,
    '_200': 200
  }
};

export const BASIC_HEADER = {
  'CLIENT_ID': 'acme',
  'CLIENT_SECRET': 'acmesecret'
};

export const BODY_TOKEN_DATA = {
  'GRANT_TYPE': 'grant_type',
  'CLIENT_ID': 'client_id',
  'SCOPE': 'scope',
  'USERNAME': 'username',
  'PASSWORD': 'password'
};

export const BODY_TOKEN_DATA_VALUE = {
  'GRANT_TYPE__PASSWORD': 'password',
  'CLIENT_ID__HEADER': BASIC_HEADER.CLIENT_ID,
  'SCOPE__OPENID': 'openid'
};

export const HTTP_HEADER = {
  'X_REDIRECT': 'x-redirect',
  'CONTENT_TYPE': 'Content-Type',
  'AUTHORIZATION': 'Authorization',
  'ACCEPT': 'Accept'
};

export const HTTP_HEADER_VALUE = {
  'CONTENT_TYPE__APP_JSON': 'application/json',
  'CONTENT_TYPE__APP_FORM_URLENCODED': 'application/x-www-form-urlencoded',
  'AUTHORIZATION__BASIC': 'Basic ' ,
  'ACCEPT__APP_JSON_TEXT_ALL': 'application/json, text/plain, */*',
  'BEARER': 'Bearer '
};

export const PARTIAL_ERROR_MESSAGE = {
  'LOWER_CASE': {
    'UNKNOWN_VALIDATION_TOKEN': 'unknown validation token',
    'ALREADY_ASSIGNED': 'already assigned',
    'UNAUTHORIZED': 'unauthorized'
  }
};

// Default language
export const DEFAULT_LANG: string = 'en';

export const VALIDATION_ACTIVATION_TOKEN_PARAMETER: string = 'activationToken';
export const VALIDATION_USERNAME_PARAMETER: string = 'username';
export const VALIDATION_UPDATE_TOKEN_PARAMETER: string = 'updatePasswordToken';
export const VALIDATION_EMAIL_PARAMETER: string = 'email';
export const VALIDATION_PASSWORD_CHANGED: string = 'passwordChanged';
export const SOCIAL_LOGIN_PARAMETER: string = 'token';
export const SOCIAL_DTO_REGISTER_PARAMETER: string = 'registrationDto';
export const SOCIAL_ERROR_DESCRIPTION: string = 'error_description';
export const REDIRECTION_PARAMETER: string = 'redirect_param';

export const DEFAULT_TOTAL_ITEMS_PER_PAGE = 10;

export const SHOULD_COMPACT_SIDEBAR_AFTER_CLICK: boolean = true;

export const TOASTER_LONG_DURATION = 8000;
export const TOASTER_SHORT_DURATION = 3000;

export const ELAPSED_TIME_CLOSE_WINDOWS = 2000;
export const BYTES_PER_KB: number = 1024;
export const BYTES_PER_MB: number = 1024 * 1024;
export const MAX_SIZE_PROFILE_PICTURE: number = 5 * BYTES_PER_MB;
export const MAX_SIZE_ANNOUNCEMENT_PICTURE: number = 800 * BYTES_PER_KB;
export const VALID_PICTURE_EXTENSION_LIST: string = '.png, .jpg, .jpeg, .gif';
export const VALID_PICTURE_TYPE_LIST: string[] = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
export const IMAGE_NO_PHOTO = 'images/no-photo.png';

export enum REST_TIMEOUT_TYPE {
  LONG = 10000,
  SHORT = 3000,

  DEFAULT = LONG,
}

export const SEE_TOP_MAIN_MENU: boolean = false;
export const SEE_TOP_LOCATION_MENU: boolean = true;

export const SHARED_PARAMETER: string = 'myParams';

export const SHOW_REFRESH_BUTTON: boolean = false;

export const REFRESH_NOTIFICATIONS_INTERVAL: number = 3000;

export const ENABLE_FLOATING_BUTTONS_AT_DUALDROP: boolean = false;

export const INVITATION_UUID = 'invitation_uuid';
