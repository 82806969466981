/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {Component} from '@angular/core';
import {AppConfigService} from '../../core/config/app-config.service';

@Component({
  selector: 'app-about',
  template: `
    <div><br>
      <h1>MyEmergency</h1>
      <br>
      <table>
        <tr>
          <td><strong>Version</strong>:</td><td>{{this.version}}</td>
        </tr>
        <tr>
          <td><strong>Build</strong>:</td><td>{{this.buildTimestamp}}</td>
        </tr>
      </table>
    </div>
  `
})
export class AboutComponent {
  public version: string;
  public buildTimestamp: string;

  constructor(private appConfig: AppConfigService) {
    this.version = this.appConfig.getVersion();
    this.buildTimestamp = this.appConfig.getBuildTimestamp();
  }
}
