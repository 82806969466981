import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from '../../../config/app-config.service';
import {Configuration} from '../../directory_service/configuration';
import {Observable} from 'rxjs';
import {WhatsAppGroupDto} from '../model/WhatsAppGroupDto';
import {UserDto} from '../../directory_service/model/userDto';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {

  protected basePath;
  protected communicationPath;
  public configuration = new Configuration();


  constructor(protected httpClient: HttpClient, protected appConfigService: AppConfigService) {
    this.basePath = appConfigService.getWhatsAppService();
    this.communicationPath = appConfigService.getCommunicationService();
  }

  public createGroup(body: WhatsAppGroupDto): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling createGroup.');
      }
      return this.httpClient.post(`${this.basePath}/${body.groupId}`, <WhatsAppGroupDto>body);
  }

  public isWhatsAppGroupCreated(groupId: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.basePath}/${groupId}`).pipe(map(item => item && item == true ? true : false)); ;
  }

  public sendInvitationLink(groupId: string, body: UserDto): Observable<any> {
    return this.httpClient.patch<UserDto>(`${this.basePath}/${groupId}/sendInvitation`, body);
  }

  public getInvitationLink(groupId: string): Observable<string> {
    return this.httpClient.get(`${this.communicationPath}/${groupId}/invitationLink`, {responseType: 'text'}).pipe(map(item => item && item.includes('http') ? item.replace(' ', '') : null));;
  }
}
