/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ConfigConfigDialog, ConfirmDialogComponent, ConfirmDialogModel, DIALOG_TYPE, ReturnedBase64Image} from '../../component/shared/dialogs/confirm-dialog.component';
import {InviteCSVDialog, InviteCSVDialogComponent} from '../../component/shared/dialog_invite_csv/invite-csv-dialog.component';

export const CONFIG_CROPPER_USER_PROFILE: ConfigConfigDialog = {
  rounded: true,
  aspectRatio: 1 / 1
}

export const CONFIG_CROPPER_ANNOUNCEMENT_BAR: ConfigConfigDialog = {
  rounded: false,
  aspectRatio: 2 / 1
}

@Injectable()
export class DialogService {
  constructor(
    private matDialog: MatDialog
  ) {}

  public showConfirmDialog(title: string, message: string, isCritical?: boolean): Observable<any> {
    const dialogData = new ConfirmDialogModel(null, title, message, !isCritical ? DIALOG_TYPE.YES_NO : DIALOG_TYPE.YES_NO_CRITICAL);
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
      disableClose: false
    });
    return dialogRef.afterClosed();
  }

  public showConfirmNotesDialog(title: string, message: string, isCritical?: boolean, textareaTitle: string = 'Observations', textareaValue: string = ''): Observable<any> {
    const dialogData = new ConfirmDialogModel(null, title, message, !isCritical ? DIALOG_TYPE.YES_NO_NOTES : DIALOG_TYPE.YES_NO_CRITICAL_NOTES, null, null, textareaTitle, textareaValue);
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
      disableClose: false
    });
    return dialogRef.afterClosed();
  }

  public showDialog(title: string, message: string): Observable<any> {
    const dialogData = new ConfirmDialogModel(null, title, message, DIALOG_TYPE.OK);
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
      disableClose: false
    });
    return dialogRef.afterClosed();
  }

  public showPictureCropperDialog(title: string, message: string, picture: File, config: ConfigConfigDialog, maxFileSize?: number): Observable<ReturnedBase64Image> {
    const dialogData = new ConfirmDialogModel(config, title, message, DIALOG_TYPE.PICTURE_CROPPER, picture, maxFileSize);
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      maxWidth: '90vw',
      maxHeight: '80vh',
      data: dialogData,
      disableClose: false
    });
    return dialogRef.afterClosed();
  }

  public showDialogCSVInvitationImport(title: string, groupId: string): Observable<any> {
    const dialogData = new InviteCSVDialog(title, groupId);
    const dialogRef = this.matDialog.open(InviteCSVDialogComponent, {
      minWidth: '55%',
      maxWidth: '100%',
      minHeight: '60%',
      data: dialogData,
      disableClose: true
    });
    return dialogRef.afterClosed();
  }
}
