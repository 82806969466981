import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClientModule} from '@angular/common/http';
import {NavigationService, ROUTE_INFO} from './core/services/tools/navigation.service';
import {AppConfigService} from './core/config/app-config.service';
import {StorageService} from './core/services/auth/storage.service';
import {ReactiveFormsModule} from '@angular/forms';
import {AuthGuard} from './core/services/auth/auth.guard';
import {MatMenuModule} from '@angular/material/menu';
import {CommonModule} from '@angular/common';
import {TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {environment} from '../environments/environment';
import {I18N_BACKEND_FOLDER_RELATIVE_PATH, I18N_DIALOG_FOLDER_RELATIVE_PATH, I18N_FILE_EXTENSION} from './core/constants/constants';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FlexLayoutModule} from '@angular/flex-layout';
import {UserControllerService} from './core/services/directory_service/api/userController.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {KeycloakTokenInterceptor} from './core/interceptors/keycloak-token-interceptor.service';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {KeycloakCustomService} from './core/services/auth/keycloak.custom.service';
import {GroupControllerService} from './core/services/directory_service/api/groupController.service';
import {MockupService} from './core/services/auth/mockup.service';
import {SharedModule} from './shared-module.module';
import {MatSelectModule} from '@angular/material/select';
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {JsonService} from './core/services/tools/json-service';
import {IpInfoService} from './core/services/auth/ipinfo.service';
import {RoleControllerService} from './core/services/directory_service/api/roleController.service';
import {ToastService} from './core/services/tools/toast-service';
import {HealthService} from './core/services/tools/health-service';
import {ObservableService} from './core/services/tools/observable-service';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DialogService} from './core/services/tools/dialog-service';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from './core/component/shared/dialogs/confirm-dialog.component';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {RolesService} from './core/services/tools/roles-service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AvatarService} from './core/services/tools/avatar-service';
import {StompWebsocketWebservice} from './core/services/tools/stomp-websocket-webservice.service';
import {ImageCropperModule} from 'ngx-image-cropper';
import {AssetControllerService} from './core/services/directory_service/api/assetController.service';
import {RequestControllerService} from './core/services/directory_service/api/requestController.service';
import {ServiceControllerService} from './core/services/directory_service/api/serviceController.service';
import {AnnouncementControllerService} from './core/services/announcement_service/api/announcementController.service';
import {WidgetService} from './core/services/widget/api/widget.service';
import {WhatsappService} from './core/services/social-media/api/whatsapp.service';
import {QRCodeModule} from 'angularx-qrcode';
import {FileSharingControllerService} from './core/services/filesharing_service/api/filesharingController.service';
//import {HttpCacheInterceptorModule, useHttpCacheLocalStorage} from "@ngneat/cashew";
import {KeycloakService} from 'keycloak-angular';
import {RxStompService} from './core/services/stomp/rx-stomp.service';
import {rxStompServiceFactory} from './core/services/stomp/rx-stomp-service-factory';
import {QuotaDialogComponent} from './pages/groups/tools/dialogs/quota-dialog.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {InviteCSVDialogComponent} from './core/component/shared/dialog_invite_csv/invite-csv-dialog.component';
import {MatFileUploadModule} from '@martyganz/mat-file-upload';
import {FormGridModule} from './core/component/shared/grid/grid.component.module';
import {DefaultRoleControllerService} from './core/services/directory_service/api/defaultRoleController.service';
//import * as Keycloak from "keycloak-js";

//export const CACHE_AVATARS_DAYS: number = 3;
export const MILISSECONDS_PER_DAY: number = 86400 * 1000;

export const MODULES_APP_COMMON = [
  CommonModule,
  FlexLayoutModule,
  ReactiveFormsModule,
  ImageCropperModule
];

/* Angular Material Modules */
const MODULES_MATERIAL = [
  MatTooltipModule,
  MatListModule,
  MatIconModule,
  MatMenuModule,
  MatSnackBarModule,
  MatExpansionModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatOptionModule,
  MatDialogModule,
  MatCardModule,
  MatButtonModule,
  DragDropModule
];

export function kcFactory(keycloakService: KeycloakService): any {
  /* Let full open URL over Keycloak - ATTENTION: This is not compatible with Angular some services (like translation) */
  if (document.location.href.endsWith('/' + ROUTE_INFO)) {    // TODO: Pending doing it in a modern and cleaner way (this.router.url)
    return () => {};
    /*return () => new Promise((resolve, reject) => {
      return reject;
    });*/
  }

  // In case of offline access the following line needs to be removed.
  return () => keycloakService.init({
    config: environment.keycloakConfig,
    // @ts-ignore
    initOptions: environment.keycloakInitOptions
  });
  /* In case of offline access the following lines needs to be added:
    return () => keycloakService.init().then(auth => {
      if (!auth) {
        keycloakService.login({
          scope: 'openid offline_access',
        });
      }
    });
  */
}

// tslint:disable-next-line:typedef
export function createTranslateLoader(httpBackend: HttpBackend) {
  // Prepare the i18n available on STATIC_I18N
  return new MultiTranslateHttpLoader(httpBackend, [
    {prefix: I18N_DIALOG_FOLDER_RELATIVE_PATH, suffix: I18N_FILE_EXTENSION + '?' + environment.version},  // /assets/i18n/dialogs/en.json
    {prefix: I18N_BACKEND_FOLDER_RELATIVE_PATH, suffix: I18N_FILE_EXTENSION + '?' + environment.version}, //  /assets/i18n/backend/en.json
  ]);
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialogComponent,
    InviteCSVDialogComponent,
    QuotaDialogComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    InviteCSVDialogComponent,
    QuotaDialogComponent
  ],
  imports: [
    // App module
    AppRoutingModule,
    // Rest module
    HttpClientModule,
    // Cache Rest system (ngneat/cashew)
    /*HttpCacheInterceptorModule.forRoot({
      ttl: CACHE_AVATARS_DAYS * 86400 * 1000,
      strategy: 'explicit'
    }),*/
    // Browser modules
    BrowserModule,
    BrowserAnimationsModule,
    // Ngx Translate
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        // useClass: CustomTranslateLoader
        useFactory: (createTranslateLoader),
        deps: [HttpBackend]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    // Common modules
    ...MODULES_APP_COMMON,
    // Material modules
    ...MODULES_MATERIAL,
    SharedModule,
    QRCodeModule,
    MatInputModule,
    MatFormFieldModule,
    MatFileUploadModule,
    FormGridModule
  ],
  exports: [
    TranslateModule,
    SharedModule,
    ConfirmDialogComponent,
    InviteCSVDialogComponent,
    QuotaDialogComponent
  ],
  providers: [
    // Security
    AuthGuard,
    // Rest services
    NavigationService,
    // App config
    AppConfigService,
    // Storage
    StorageService,
    // Cache Rest system (ngneat/cashew) - Let use local storage
    /*useHttpCacheLocalStorage,*/
    // DTO access
    UserControllerService,
    GroupControllerService,
    RoleControllerService,
    AssetControllerService,
    ServiceControllerService,
    RequestControllerService,
    AnnouncementControllerService,
    FileSharingControllerService,
    DefaultRoleControllerService,
    WidgetService,
    WhatsappService,
    // Mockup
    MockupService,
    // Json
    JsonService,
    // IpInfo
    IpInfoService,
    // Tools
    ToastService,
    DialogService,
    HealthService,
    ObservableService,
    RolesService,
    AvatarService,
    // Material
    MatNativeDateModule,
    MatDatepickerModule,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    // Keycloak
    KeycloakCustomService,
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: kcFactory,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakTokenInterceptor,
      multi: true
    },
    // Stop Websocket configuration
    // {
    //   provide: RxStompConfig,
    //   useValue: rxStompWebsocketConfig,
    // },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      // deps: [RxStompConfig],
    },
    StompWebsocketWebservice
  ],
  bootstrap: [AppComponent]
})
// @ts-ignore
export class AppModule {}
