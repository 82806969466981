/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {Injectable} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {UserControllerService} from '../directory_service/api/userController.service';
import {Observable, of, Subscriber} from 'rxjs';
import {map, share, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {StorageService} from "../auth/storage.service";
import {Utils} from "./utils";

export const AVATARS_MIN: number = 1;
export const AVATARS_ORIGINAL_TOTAL: number = 59;
export const AVATARS_TOTAL: number = AVATARS_ORIGINAL_TOTAL + 2;
export const AVATAR_PATH: string = 'assets/emergency.lu/images/avatars/';
export const DEFAULT_AVATAR_FIGURE: string = 'empty_default.svg';
const FIRST_CHARS_ON_SVG_BASE64: string = btoa("<svg ").substr(0, 6);
const PASTEL_COLOR_ARRAY: Array<string> = [
  // Candies Be Love
  '#F7F6CF',
  '#B6D8F2',
  '#F4CFDF',
  '#5784BA',
  '#9AC8EB',
  // Love-caroon
  '#CCD4BF',
  '#E7CBA9',
  '#EEBAB2',
  '#F5F3E7',
  '#F5E2E4',
  // Sugary-Colored Pills
  '#F5BFD2',
  '#E5DB9C',
  '#D0BCAC',
  '#BEB4C5',
  '#E6A57E',
  // Mint to Be
  '#218B82',
  '#9AD9DB',
  '#E5DBD9',
  '#98D4BB',
  '#EB96AA',
  // Donut Move
  '#C6C9D0',
  '#C54B6C',
  '#E5B3BB',
  '#C47482',
  '#D5E4C3',
  // Fleeting Thoughts
  '#F9968B',
  '#F27348',
  '#26474E',
  '#76CDCD',
  '#2CCED2',
  // Fly Awe-way
  '#B8E0F6',
  '#A4CCE3',
  '#37667E',
  '#DEC4D6',
  '#7B92AA',
  // Crystallized Beauty
  '#DDF2F4',
  '#84A6D6',
  '#4382BB',
  '#E4CEE0',
  '#A15D98',
  // Ice See You Cream
  '#DC828F',
  '#F7CE76',
  '#E8D6CF',
  '#8C7386',
  '#9C9359',
  // Sipping' Sweet
  '#F4C815',
  '#F9CAD7',
  '#A57283',
  '#C1D5DE',
  '#DEEDE6',
  // Mini Pastel Pops
  '#E9BBB5',
  '#E7CBA9',
  '#AAD9CD',
  '#E8D595',
  '#8DA47E',
  // Slip to Sleep
  '#CAE7E3',
  '#B2B2B2',
  '#EEB8C5',
  '#DCDBD9',
  '#FEC7BC'
  // Etc on: https://offeo.com/learn/20-pastel-spring-summer-color-palettes/
]

export interface IAvatar {
  filename: string;
  offset: number;
}

// declare global {
//   var globalsPhotoPictures: Map<string, SafeUrl>;
// }

@Injectable()
export class AvatarService {
  // private readonly NEUTRAL_AVATAR_FILENAME = AVATAR_PATH + 'empty.svg';
  public readonly millisecondsUntilMidnight: number = Utils.getMillisecondsUntilMidnight();

  constructor(
    private userControllerService: UserControllerService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private storageService: StorageService
  ) {}

  public getAvatarNameByIndex(offset: number): IAvatar {
    if (!offset && offset != 0) {
      offset = 0;
    }
    if (offset > AVATARS_TOTAL) {
      offset = AVATARS_MIN;
    }
    if (offset < AVATARS_MIN) {
      offset = AVATARS_TOTAL;
    }
    const isCustomAvatar: boolean = offset > AVATARS_ORIGINAL_TOTAL;
    return {
      filename: AVATAR_PATH + (offset < 10 ? '0' + offset : offset) + (isCustomAvatar ? 'c' : '') + '.svg',
      offset: offset
    }
  }

  /*public isNeutralAvatar(fileName: string): boolean {
    return fileName === this.NEUTRAL_AVATAR_FILENAME;
  }*/

  private getSanitizedDrawnText(firstName: string, lastName: string, email: string): SafeUrl {
    const text: string = this.getTwoLetters(firstName, lastName);

    const size = 50;  // Bigger it is and quality is better
    const canvas: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
    canvas.width = size;
    canvas.height = size;
    var context = canvas.getContext('2d');

    // Draw background
    context.fillStyle = this.getColorByText(email, firstName, lastName);
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw text
    context.fillStyle = '#ffffff';
    context.font = (size/2.1) + 'px Outfit, Roboto, Arial';
    context.textAlign = 'center';
    // Draw shadows
    // context.shadowColor = '#cfcfcf';
    // context.shadowBlur =  2;
    // context.shadowOffsetX = 0.4;
    // context.shadowOffsetY = 0.4;
    context.fillText(text, size / 2, size / 1.5);

    return this.sanitizer.bypassSecurityTrustUrl(canvas.toDataURL('image/png'));
  }

  private getDrawnText(firstName: string, lastName: string, email: string): string[] {
    const text: string = this.getTwoLetters(firstName, lastName);

    const size = 50;  // Bigger it is and quality is better
    const canvas: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
    canvas.width = size;
    canvas.height = size;
    var context = canvas.getContext('2d');

    // Draw background
    context.fillStyle = this.getColorByText(email, firstName, lastName);
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw text
    context.fillStyle = '#ffffff';
    context.font = (size/2.1) + 'px Outfit, Roboto, Arial';
    context.textAlign = 'center';
    context.fillText(text, size / 2, size / 1.5);

    return Array.from(canvas.toDataURL("image/png").split(';base64,')[1]);
  }

  private getTwoLetters(firstName: string, lastName: string): string {
    const hasFirstName: boolean = firstName && firstName.length > 0;
    const hasLastName: boolean = lastName && lastName.length > 0;
    let ret = '??';
    if (hasFirstName && hasLastName) {
      ret = firstName[0] + lastName[0];
    }
    else if (hasFirstName) {
      if (firstName.length > 1) {
        ret = firstName[0] + firstName[1];
      } else {
        ret = firstName[0] + firstName[0];
      }
    }
    else if (hasLastName) {
      if (lastName.length > 1) {
        ret =lastName[0] + lastName[1];
      } else {
        ret =lastName[0] + lastName[0];
      }
    }
    return ret.toUpperCase();
  }

  private getColorByText(email: string, firstName: string, lastName: string): string {
    const all: string = email + '!' + firstName + '!' + lastName;
    let total: number = 0;
    for (let index = 0; index < all.length; index++) {
      total += all.charCodeAt(index);
    }
    const index: number = total % PASTEL_COLOR_ARRAY.length;
    return PASTEL_COLOR_ARRAY[index];
  }

  private cleanSanitization(_base64Image: string[] | string): string[] | string {
    if (typeof _base64Image === 'string' && _base64Image['changingThisBreaksApplicationSecurity']) {
      const parts: string[] = _base64Image['changingThisBreaksApplicationSecurity'].split(',');
      return parts[parts.length - 1];
    }
    return _base64Image;
  }

  public getSanitizedBase64Image(_base64Image: string[] | string): SafeUrl {
    _base64Image = this.cleanSanitization(_base64Image);

    let base64Image, objectURL;
    if (typeof _base64Image === 'string') {
      base64Image = _base64Image;
    } else {
      base64Image = _base64Image.join('');
    }
    if (base64Image.startsWith(FIRST_CHARS_ON_SVG_BASE64)) {
      objectURL = 'data:image/svg+xml;base64,' + base64Image;
    } else {
      objectURL = 'data:image/jpeg;base64,' + base64Image;
    }
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  public getSanitizedBlob(blob: Blob): SafeUrl {
    let url = URL.createObjectURL(blob);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public getBlobFromUrl(fileName: any): Observable<Blob> {
    return this.getImageFile('/' + fileName);
  }

  private getImageFile(filePath: string): Observable<any> {
    return this.http.get(filePath, { responseType: 'blob' });
  }

  public get globalThisPhotoPictures(): Map<string, SafeUrl> {
    if (!globalThis.globalsPhotoPictures) {
      globalThis.globalsPhotoPictures = new Map();
    }
    return globalThis.globalsPhotoPictures;
  }

  public setUserIcon(userId: string, primaryEmail: string, file: Blob): Observable<Array<string>> {
    return this.userControllerService.setProfilePictureForm(userId, file).pipe(
      tap(item => {
        this.globalThisPhotoPictures.delete(userId);
        this.storageService.removeAvatarCode(primaryEmail);
      })
    )
  }

  public removeUserIcon(userId: string, primaryEmail: string, justLocally?: boolean): Observable<any> {
    if (justLocally === true) {
      return of(this.globalThisPhotoPictures.delete(userId));
    } else {
      return this.userControllerService.removeProfilePicture(userId).pipe(
        tap(item => {
          this.globalThisPhotoPictures.delete(userId);
          this.storageService.removeAvatarCode(primaryEmail);
        })
      )
    }
  }

  public getUserAvatar(userId: string, firstName: string, lastName: string, email: string, notUseCache?: boolean): Observable<SafeUrl> {
    const existingSafeUrl: SafeUrl = this.globalThisPhotoPictures.get(userId);
    if (existingSafeUrl && !notUseCache) {
      return of(existingSafeUrl);
    }
    // if (!global.globalsPhotoPictures) {
    //   global.globalsPhotoPictures = new Map();
    // }
    const localAvatar: string = this.storageService.getAvatarCode(email, this.millisecondsUntilMidnight);  // CACHE_AVATARS_DAYS * MILISSECONDS_PER_DAY
    if (localAvatar) {
      return of(this.getSanitizedBase64Image(localAvatar));
    }
    return this.userControllerService.getProfilePicture(userId).pipe(
      map(avatar => {
        if (avatar.picture) {
          this.storageService.setAvatarCode(email, avatar.picture);
          return this.getSanitizedBase64Image(avatar.picture);
        } else {
          const pic = this.getDrawnText(firstName, lastName, email);
          this.storageService.setAvatarCode(email, pic);
          return this.getSanitizedDrawnText(firstName, lastName, email);
        }
      }),
      tap(item => {
        this.globalThisPhotoPictures.set(userId, item);
      }),
      share() // Prevent multiple firing requests
    )
  }

  public getObservableFromFileReader(ev): Observable<string> {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer((<any>ev.target).files[0]);
    return Observable.create((observer: Subscriber<string>): void => {
      // if success
      fileReader.onload = ((ev: ProgressEvent): void => {
        // let binary = '';
        // let bytes = new Uint8Array((<any>ev.target).result);
        // let length = bytes.byteLength;
        // for (let i = 0; i < length; i++) {
        //   binary += String.fromCharCode(bytes[i]);
        // }
        observer.next((<any>ev.target).result); // binary
        observer.complete();
      })
      fileReader.onerror = (error: any): void => {
        observer.error(error);
      }
    });
  }

  public getNeutralAvatar(firstName: string, lastName: string, email: string): SafeUrl {
    if (email) {
      return this.getSanitizedDrawnText(firstName, lastName, email);
    } else {
      return null;
    }
  }

  public b64toBlob(b64Data: string, contentType?: string): Blob {
    const parts: Array<string> = b64Data.split(';base64,');
    const rawB64Data: string = parts[parts.length - 1];
    const byteCharacters = atob(rawB64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    if (contentType) {
      return new Blob([byteArray], {type: contentType});  // 'image/png'
    } else {
      return new Blob([byteArray]);

    }
  }

  public dataURItoBlob(dataURI: string, contentType: string): Blob {
    const parts = dataURI.split(',');
    const binary = atob(parts[parts.length - 1]);
    let array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: contentType // 'image/jpeg'
    });
  }
}
