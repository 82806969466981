import {HostListener, Injectable, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppConfigService} from '../../config/app-config.service';
import {LeftMenuParamWindowType} from '../../models/menus/left-menu.model';
import {SHARED_PARAMETER} from '../../constants/constants';
import {GridActionEnum} from '../../component/shared/grid/grid.component.constants';
import {GroupDto} from '../directory_service/model/groupDto';

export const STRATEGY_PATH_MATCH_PREFIX = 'prefix';
export const STRATEGY_PATH_MATCH_FULL = 'full';

export const ROUTE_EMTPY = '';
export const ROUTE_ANY = '**';

/* Please keep the right tab structure */
export const ROUTE_GROUPEROOT_AUTH = 'auth';
  export const ROUTE_ACTION_LOGIN = 'login';

export const ROUTE_GROUPEROOT_PAGE = '';  // Old value: '/page'
  export const ROUTE_INFO = 'info';
  export const ROUTE_PRIVACY_POLICY = 'privacy-policy';
  export const ROUTE_DASHBOARD = 'dashboard';
  export const ROUTE_ANNOUNCEMENT = 'announcement';
  export const ROUTE_USER = 'user';
  export const ROUTE_GROUPE = 'group';
  export const ROUTE_GROUPE_TEAM = 'team'; // 'team';
  export const ROUTE_GROUPE_NETWORK = 'network'; // 'network';
  export const ROUTE_GROUPE_ORGANISATION = 'organization'; // 'organisation';
    export const ROUTE_ACTION_LIST = 'list';
    export const ROUTE_ACTION_NOTIFICATIONS_LIST = 'notifications';
    export const ROUTE_ACTION_EDIT = 'edit';
    export const ROUTE_ACTION_CREATE = 'create';
    export const ROUTE_ACTION_ME = 'me'
    export const ROUTE_ACTION_VIEW = 'view';
    export const ROUTE_ACTION_JOIN = 'join';
    export const ROUTE_ACTION_TOOLS = 'tools';
    export const ROUTE_ACTION_DEFAULT_SERVICES = 'default-services';
    export const ROUTE_ACTION_USER_LIST = 'users';
    export const ROUTE_ACTION_DOMAINS = 'domains';
  export const ROUTE_GROUP_DASHBOARD = 'dashboard';

export const RESERVED_WORDS = [
  ROUTE_GROUPEROOT_PAGE, ROUTE_GROUPEROOT_AUTH, ROUTE_ACTION_LOGIN, ROUTE_INFO, ROUTE_USER, ROUTE_GROUPE, ROUTE_GROUPE_TEAM, ROUTE_GROUPE_NETWORK, ROUTE_GROUPE_ORGANISATION, ROUTE_GROUP_DASHBOARD, ROUTE_PRIVACY_POLICY,
  ROUTE_ACTION_LIST, ROUTE_ACTION_NOTIFICATIONS_LIST, ROUTE_ACTION_EDIT, ROUTE_ACTION_CREATE, ROUTE_ACTION_ME, ROUTE_ACTION_VIEW, ROUTE_ACTION_JOIN, ROUTE_ACTION_TOOLS, ROUTE_ACTION_DEFAULT_SERVICES, ROUTE_ACTION_USER_LIST, ROUTE_ACTION_DOMAINS
];

/********************************************************** ROUTING **************************************************************************************************************/
export const ROUTE_FULL_PAGES_AUTH_LOGIN: string = ('/' + ROUTE_GROUPEROOT_AUTH + '/' + ROUTE_ACTION_LOGIN);
export const ROUTE_FULL_DASHBOARD: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUP_DASHBOARD);

export const ROUTE_FULL_PAGES_ORGANISATION_LIST: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_ORGANISATION + '/' + ROUTE_ACTION_LIST);
export const ROUTE_FULL_PAGES_ORGANISATION_LIST_USERS: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_ORGANISATION + '/:' + SHARED_PARAMETER + '/' + ROUTE_ACTION_USER_LIST);
export const ROUTE_FULL_PAGES_ORGANISATION_EDIT: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_ORGANISATION + '/' + ROUTE_ACTION_EDIT);
export const ROUTE_FULL_PAGES_ORGANISATION_VIEW: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_ORGANISATION + '/' + ROUTE_ACTION_VIEW);

export const ROUTE_FULL_PAGES_NETWORK_LIST: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_NETWORK + '/' + ROUTE_ACTION_LIST);
export const ROUTE_FULL_PAGES_NETWORK_LIST_USERS: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_NETWORK + '/:' + SHARED_PARAMETER + '/' + ROUTE_ACTION_USER_LIST);
export const ROUTE_FULL_PAGES_NETWORK_EDIT: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_NETWORK + '/' + ROUTE_ACTION_EDIT);
export const ROUTE_FULL_PAGES_NETWORK_VIEW: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_NETWORK + '/' + ROUTE_ACTION_VIEW);
export const ROUTE_FULL_PAGES_NETWORK_JOIN: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_NETWORK + '/' + ROUTE_ACTION_JOIN);

export const ROUTE_FULL_PAGES_TEAM_LIST: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_TEAM + '/' + ROUTE_ACTION_LIST);
export const ROUTE_FULL_PAGES_TEAM_LIST_USERS: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_TEAM + '/:' + SHARED_PARAMETER + '/' + ROUTE_ACTION_USER_LIST);
export const ROUTE_FULL_PAGES_TEAM_EDIT: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_TEAM + '/' + ROUTE_ACTION_EDIT);
export const ROUTE_FULL_PAGES_TEAM_VIEW: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_TEAM + '/' + ROUTE_ACTION_VIEW);
export const ROUTE_FULL_PAGES_TEAM_CREATION: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_TEAM + '/' + ROUTE_ACTION_CREATE);
export const ROUTE_FULL_PAGES_TEAM_JOIN: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_TEAM + '/' + ROUTE_ACTION_JOIN);

export const ROUTE_FULL_PAGES_GROUP_NOTIFICATIONS_LIST: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE + '/' + ROUTE_ACTION_NOTIFICATIONS_LIST);
export const ROUTE_FULL_PAGES_TOOLS: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE + '/' + ROUTE_ACTION_TOOLS);
export const ROUTE_FULL_PAGE_DEFAULT_SERVICES: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE + '/' + ROUTE_ACTION_DEFAULT_SERVICES);
export const ROUTE_FULL_PAGES_ALLOWED_DOMAIN: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE_ORGANISATION + '/' + ROUTE_ACTION_DOMAINS);

export const ROUTE_FULL_PAGES_USER_LIST: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_GROUPE + '/:' + SHARED_PARAMETER + '/' + ROUTE_ACTION_USER_LIST);
export const ROUTE_FULL_PAGES_USER_EDIT: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_USER + '/' + ROUTE_ACTION_EDIT);
export const ROUTE_FULL_PAGES_USER_VIEW: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_USER + '/' + ROUTE_ACTION_VIEW);
export const ROUTE_FULL_PAGES_USER_EDIT_ME: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_USER + '/' + ROUTE_ACTION_EDIT + '/' + ROUTE_ACTION_ME);

export const ROUTE_FULL_PAGES_DASHBOARD: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_DASHBOARD);

export const ROUTE_FULL_PAGES_ANNOUNCEMENT_EDIT: string = (ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_ANNOUNCEMENT + '/' + ROUTE_ACTION_EDIT);

export const ROUTE_FULL_PAGES_PRIVACY_POLICY: string = ROUTE_GROUPEROOT_PAGE + '/' + ROUTE_PRIVACY_POLICY

/*********************************************************************************************************************************************************************************/
export const DEFAULT_ROUTE_WHEN_INITIALIZE: string = ROUTE_DASHBOARD; // (ROUTE_USER + '/' + ROUTE_ACTION_EDIT + '/' + ROUTE_ACTION_ME);
export const DEFAULT_FULL_ROUTE_WHEN_INITIALIZE: string = (ROUTE_GROUPEROOT_PAGE + '/' + DEFAULT_ROUTE_WHEN_INITIALIZE);
/*********************************************************************************************************************************************************************************/

@Injectable()
export class NavigationService implements OnInit, OnDestroy {
  constructor(
    public router: Router,
    public appConfig: AppConfigService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  @HostListener('window:beforeunload')
  ngOnBeforeUnload() {
  }

  /*******************************************************************************************************************************************/

  private getRoute(relativeLoc: string): string {
    return relativeLoc;
  }

  public goTo(relativeLoc: string, byUrl?: boolean, newPage?: boolean): void { // , queryParams?: Params | string
    if (newPage) {
      this.redirectTo(relativeLoc);

    } else if (byUrl) {
      /*if (typeof queryParams == 'string') {
        this.router.navigateByUrl(this.getRoute(relativeLoc) + (queryParams ? '/' + queryParams : ''));
      } else {
        this.router.navigateByUrl(this.getRoute(relativeLoc), queryParams);
      }*/
      this.router.navigateByUrl(this.getRoute(relativeLoc));

    } else {
      //this.router.navigate([this.getRoute(relativeLoc), queryParams]);
      this.router.navigate([this.getRoute(relativeLoc)]);

    }
  }

  // Force redirect to URI even if it is the same
  private redirectTo(uri: string) {
    //TODO Check a better way to do it: https://stackoverflow.com/questions/40983055/how-to-reload-the-current-route-with-the-angular-2-router
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([uri]));
  }

  public goToMenu(url: string, random?: boolean) {  // menu: LeftMenuModel
    if (url) { // menu.url
      /*if (menu.sharedId || menu.sharedUsername || menu.sharedEmail || menu.sharedParamWindow || menu.letReCallIt) { //  || menu.sharedName
        this.goTo(menu.url, true, menu && menu.sharedId ? menu.sharedId : '');  // old GridTools.getWindowActionParamsForUrl(menu)
      } else {*/
      this.goTo(url, true, random); // menu.url
      //}
    }
  }

  /**
   * Navigate to edit menu of related url
   * @param url
   * @param actionId (in the case of groups it is the group name)
   * @param random
   */
  public goToMenuEdit(url: string, actionId: string, random?: boolean): void {  // menu: LeftMenuModel
    if (url) {
      const newUrl: string = (decodeURIComponent(url.replace('/' + ROUTE_ACTION_CREATE, '/' + ROUTE_ACTION_EDIT).replace('/' + ROUTE_ACTION_VIEW, '/' + ROUTE_ACTION_EDIT)) + '/' + actionId).replace('//', '/');
      this.goToMenu(newUrl, random);
    }
  }

  /**
   * Navigate to view menu of related url
   * @param url
   * @param actionId (in the case of groups it is the group name)
   * @param random
   */
  public goToMenuView (url: string, actionId: string, random?: boolean): void {  // menu: LeftMenuModel
    if (url) {
      const newUrl: string = (decodeURIComponent(url.replace('/' + ROUTE_ACTION_CREATE, '/' + ROUTE_ACTION_VIEW).replace('/' + ROUTE_ACTION_EDIT, '/' + ROUTE_ACTION_VIEW)) + '/' + actionId).replace('//', '/');
      this.goToMenu(newUrl, random);
    }
  }

  /*public goToMenuById(url: string, userId: string) {
    this.goToMenu({
      url: url,
      sharedId: userId
    })
  }*/

  public getGroupRoute(groupDto: GroupDto, sharedParamWindow: LeftMenuParamWindowType, action: GridActionEnum): string {
    let groupType: string;
    if (sharedParamWindow == LeftMenuParamWindowType.ORGANISATION) {
      groupType = ROUTE_GROUPE_ORGANISATION
    } else if (sharedParamWindow == LeftMenuParamWindowType.NETWORK) {
      groupType = ROUTE_GROUPE_NETWORK
    } else if (sharedParamWindow == LeftMenuParamWindowType.TEAM) {
      groupType = ROUTE_GROUPE_TEAM
    }

    let route: string;
    if (action == GridActionEnum.singleEditableCustomAction1 && groupDto) {
      route = groupType + '/' + groupDto.id + '/' + ROUTE_ACTION_USER_LIST;
    } else if (action == GridActionEnum.singleViewAction && groupDto) {
      route = groupType + '/' + ROUTE_ACTION_VIEW + '/' + groupDto.name;
    } else if (action == GridActionEnum.singleEditAction && groupDto) {
      route = groupType + '/' + ROUTE_ACTION_EDIT + '/' + groupDto.name;
    } else if (action == GridActionEnum.singleCreateAction) {
      route = groupType + '/' + ROUTE_ACTION_CREATE;
    } else {
      route = '???';
    }
    return route && route.startsWith('/') ? route : '/' + route;
  }

  /*******************************************************************************************************************************************/
}
