/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */
import * as moment from 'moment';
import * as _ from 'underscore';
import {LeftMenuParamWindowType} from '../../models/menus/left-menu.model';
import {GroupDto} from '../directory_service/model/groupDto';
import {PHONE_NUMBER_COUNTRY_SEPARATOR} from '../../constants/form.constants';
import {BYTES_PER_KB, BYTES_PER_MB} from '../../constants/constants';
import {ChangeDetectorRef, ElementRef, Renderer2} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';

const __ = require('lodash');

export class Utils {
  public static clone<T>(instance: T): T {
    const copy = new (instance.constructor as { new (): T })();
    Object.assign(copy, instance);
    return copy;
  }

  public static cloneArray<T>(array: Array<T>): Array<T> {
    if (!array) {
      return array;
    } else {
      const newArray = [];
      array.forEach(val => newArray.push(Utils.cloneObject(val)));
      return newArray;
    }
  }

  public static cloneComplexArray<T>(array: Array<T>): Array<T> {
    return array ? JSON.parse(JSON.stringify(array)) : [];
    // if (!array) {
    //   return array;
    // } else {
    //   let newArray = [];
    //   array.forEach(val => {
    //     if (!Array.isArray(val)) {
    //       newArray.push(Utils.cloneObject(val))
    //     } else {
    //       newArray = Utils.cloneArray(val);
    //     }
    //   });
    //   return newArray;
    // }
  }

  public static cloneComplexObject<T>(item: T): T {
    return item ? JSON.parse(JSON.stringify(item)) : item;
  }

  public static cloneMap<S, T>(map: Map<S, T>): Map<S, T> {
    if (!map) {
      return map;
    } else {
      return new Map(map);
    }
  }
  public static cloneObject<T>(object: T): T {
    return Object.assign({}, object);
  }

  public static getEnumList(varEnum: any): Array<string> {
    if (varEnum) {
      try {
        return Object.keys(varEnum);
      } catch (e) {}
    }
    return new Array();
  }

  public static getClassName(clasz: any): string {
    try {
      if (clasz.name) {
        return clasz.name;
      } else if (clasz.constructor.name) {
        return clasz.constructor.name;
      }
    } catch (e) {}
    return 'unknown component name';
  }

  public static getMoment(dt: string | Date): moment.Moment {
    return moment(dt);
  }

  public static getCurrentMoment(): moment.Moment {
    return Utils.getMoment(new Date());
  }

  public static getCurrentMomentWithoutTime(): moment.Moment {
    return Utils.getMoment(new Date()).set({hour:0,minute:0,second:0,millisecond:0});
  }

  public static getStringFromMoment(mnt: moment.Moment, format: string): string {
    if (mnt) {
      return mnt.format(format);
    }
    return null;
  }

  public static getRemoveHtmlCode(str: string): string {
    if (str) {
      return str.replace(/<[^>]*>?/gm, '')
    } else {
      return '';
    }
  }

  public static addToInlineList(list: string, newItem: string, separator: string): string {
    let retList: string = (list ? list : '');
    retList += (retList !== '' ? separator : '') + newItem;
    return retList;
  }

  public static getCurrentTimeInMillis(): number {
    return Date.now();
  }

  public static getDifferenceDatesInDays(date1: moment.Moment, date2: moment.Moment): number {
    return date1.diff(date2, 'days');
  }

  public static getHumanDifferenceDates(date1: Date, date2: Date): string {
    const diffMs: number = date2.getTime() - date1.getTime();
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    let str = '';
    if (diffDays) {
      str += diffDays + (diffDays == 1 ? ' day' : ' days');
    }
    if (diffHrs) {
      if (str != '') {
        str += ', ';
      }
      str += diffHrs + (diffHrs == 1 ? ' hour' : ' hours');
    }
    if (diffMins) {
      if (str != '') {
        str += ', ';
      }
      str += diffMins + (diffMins == 1 ? ' minute' : ' minutes');
    }
    return str;
  }

  public static roundToString(value: number, precision: number): string {
    const multiplier: number = Math.pow(10, precision || 0);
    return (Math.round(value * multiplier) / multiplier).toFixed(precision);
  }

  public static getCountryByLanguage(lang: string): string {
    switch (lang) {
      case 'es':
        return 'es';
        break;
      case 'fr':
        return 'fr';
        break;
      case 'en':
        return 'gb';
        break;
      default:
        return lang;
        break;
    }
  }

  public static isNumber(val: string, onlyInteger: boolean): boolean {
    if (val || val + '' === '0') {
      const nmb = Number(val);
      const isNumber = !Number.isNaN(nmb);
      if (!onlyInteger || !isNumber) {
        return isNumber;
      } else {
        return Math.floor(nmb) == nmb;      }
    } else {
      return false;
    }
  }

  public static isPhoneNumber(val: string): boolean {
    return Utils.isNumber(val, true);
  }

  public static isUrl(val: string): boolean {
    return val && !Utils.isNumber(val, true) && (val.trim().toLowerCase().startsWith('https://') || val.trim().toLowerCase().startsWith('http://'));
  }

  public static sortList(plist: Array<any>, orderBy: string, orderDirection: string): Array<any> {
    return Utils.sortListPPT(plist, orderBy, orderDirection);
  }

  public static sortListSimple(plist: Array<string>, orderDirection: string): Array<string> {
    if (orderDirection == 'asc') {
      return __.sortBy(plist);
    } else if (orderDirection == 'desc') {
      return __.sortBy(plist).reverse();
    } else {
      console.error('#Bad sorting direction');
      return plist;
    }
  }

  public static sortNPageList(plist: Array<any>, orderBy: string, orderDirection: string, page: number, pageSize: number, totalItems: number): any[] {
    return Utils.sortListPPT(plist, orderBy, orderDirection, page, pageSize, totalItems);
  }

  private static sortListPPT(plist: Array<any>, orderBy: string, orderDirection: string, page?: number, pageSize?: number, totalItems?: number): any[] {
    let list: any[] = plist;
    //Sort
    try {
      if (orderDirection == 'asc') {
        //list = _.sortBy(list, orderBy);
        list = _.sortBy(list, (i) => i[orderBy].toLowerCase());
      } else if (orderDirection == 'desc') {
        // list = _.sortBy(list, orderBy).reverse();
        list = _.sortBy(list, (i) => i[orderBy].toLowerCase()).reverse();
      } else {
        console.warn('#Error sorting: no direction indicated');
      }
      console.info('##Info: array sorted by "' + orderBy + '" and direction "' + orderDirection + '"');
    } catch (e) {
      console.error('##Error: not possible sort your array by "' + orderBy + '" and direction "' + orderDirection + '"');
    }

    if (pageSize && totalItems) {
      const retList = [];
      const init = ((page - 1) * pageSize);  //-1 because arrays start from 0
      const end = init + pageSize - 1;        //-1 because arrays start from 0
      for (let i = init; i <= end && i <= totalItems && i < plist.length; i++) {
        retList.push(list[i]);
      }
      return retList;
    } else {
      return list;
    }
  }

  public static validateDateFormat(str: string, format: string): boolean {
    return moment(str, format, true).isValid();
  }

  public static getDateFormat(str: string, format: string): moment.Moment {
    return moment(str, format, true);
  }

  public static getStringFromStringDateFormat(str: string, format: string): string {
    return moment(str, format, true).format(format);
  }

  public static getStringFromDateFormat(date: Date, format: string): string {
    return moment(date).format(format);
  }

  public static getStringFromMomentFormat(mmt: moment.Moment, format: string): string {
    return moment(mmt, format, true).format(format);
  }

  /*
  * Having a string date formatted this function let truncate it according to a format
  * The point is that the date is already formatted on this format but could have some extra we need to remove
  *
  * i.e. str = '2022-08-26T00:00:00' and format = 'yyyy-MM-DD'
  *         => '2022-08-26'
  */
  public static getLiteralStringDateFormatted(str: string, format: string): string {
    return str && format ? str.substr(0, format.length) : null;
  }

  public static validatePattern(str: string, pattern: string, dateFormat?: string): boolean {
    return Utils.validateRegularPattern(dateFormat && str ? str.substr(0, dateFormat.length) : str, new RegExp(pattern));
  }

  public static validateRegularPattern(str: string, pattern: RegExp): boolean {
    return pattern.test(str);
  }

  public static isTextSeparatedBySpacesIncludingKey(str: string, key: string, inversed: boolean = false): boolean {
    if (!key || key.length == 0) {
      return true;
    } else if (!str || str.length == 0) {
      return false;
    } else {
      const arr = inversed ? key.split(' ') : str.split(' ');
      const keyLowerCase: string = (inversed ? str : key).toLowerCase();
      let found: number = 0;
      for (const item of arr) {
        if (inversed && keyLowerCase.includes(item.toLowerCase())) {
          found++;
        } else if (!inversed && item.toLowerCase().includes(keyLowerCase)) {
          found++;
        }
      }
      return found == arr.length;
    }
  }

  /*
  * Get value of object by key. It supports the separator '.'
  */
  public static getRowValue(row, keys, separator: string): any {
    if (!row || !keys) {
      return row;
    } else {
      const parts = keys.split(separator);
      let res = row;
      for (const part of parts) {
        res = res[part];
      }
      return res;
    }
  }

  public static isTextIncludingKeySeparatedBySpaces(str: string, key: string): boolean {
    return Utils.isTextSeparatedBySpacesIncludingKey(str, key, true);
  }

  public static getGroupTypeEnum(groupType: LeftMenuParamWindowType): GroupDto.GroupTypeEnum {
    switch(groupType) {
      case LeftMenuParamWindowType.ORGANISATION:
        return GroupDto.GroupTypeEnum.ORG;
      case LeftMenuParamWindowType.NETWORK:
        return GroupDto.GroupTypeEnum.NET;
      case LeftMenuParamWindowType.TEAM:
        return GroupDto.GroupTypeEnum.TEAM;
      default:
        return null;
    }
  }

  public static getInternationalPrefixPhone(str: string): string {
    if (str) {
      const phoneNumberParts = str.split(PHONE_NUMBER_COUNTRY_SEPARATOR);
      if (phoneNumberParts.length >= 2) {
        return phoneNumberParts[0];
      }
    }
    return '';
  }

  public static getNotInternationalPrefixPhone(str: string): string {
    if (str) {
      const phoneNumberParts = str.split(PHONE_NUMBER_COUNTRY_SEPARATOR);
      if (phoneNumberParts.length >= 2) {
        return str.substr(phoneNumberParts[0].length + PHONE_NUMBER_COUNTRY_SEPARATOR.length);
      }
    }
    return '';
  }

  /*
  * Convert keyValue list to array of objects
  * The Map<key,value> => List<key,value>
  */
  public static convertKeyValyeToArray(keyValue, orderByKey?: boolean): Array<{key: string, value: string}> {
    // Convert keyValue list to list of objects
    let arr = [];
    if (keyValue) {
      for (const key in keyValue) {
        arr.push({key: key, value: keyValue[key]});
      }
      if (orderByKey == true && arr.length > 0) {
        arr = _.sortBy(arr, 'key');
      }
    }
    return arr;
  }

  public static convertKeyValyeToArrayAndBindSecondMap(keyValue: Map<string, string>, keyValueDescription: Map<string, string>, orderByDescription?: boolean): Array<{key: string, value: string, description: string}> {
    let arr = [];
    if (keyValueDescription) {
      for (const key in keyValue) {
        arr.push({key: key, value: keyValue[key], description: keyValueDescription[key]});
      }
      if (orderByDescription == true) {
        arr = _.sortBy(arr, 'description');
      }
    }
    return arr;
  }

  public static removeItemByIdInArray(arr: Array<any>, key: string, id: string, propertyKey: string, propertyValue: string): Array<any> {
    // _.reject(arr, function(item) { return item[key] === id});
    return arr.filter(obj => !(obj[key] === id && obj[propertyKey] === propertyValue));
  }

  public static removeItemsByIdInArray(arr: Array<any>, key: string, id: Array<any>): Array<any> {
    // _.reject(arr, function(item) { return item[key] === id});
    return arr.filter(obj => !id.includes(obj[key]));
  }

  /* It is like a full camelcase
  *  I.e.: 'hello_world' => 'Hello World'
  */
  public static getStartCase(str: string): string {
    return __.startCase(str.toLowerCase());
  }

  public static deepClone = obj => {
    const oldState = history.state;
    history.replaceState(obj, null);
    const clonedObj = history.state;
    history.replaceState(oldState, null);
    return clonedObj;
  }

  public static getHumanFileSize(bytes: number): string {
    if (bytes < BYTES_PER_MB) {
      return Math.round(bytes / BYTES_PER_KB) + ' KB';
    } else {
      return Math.round(bytes / BYTES_PER_MB) + ' MB';
    }
  }

  public static geti18nKey(fullKey: string): string {
    if (fullKey) {
      const parts: Array<string> = fullKey.split('.');
      if (parts.length > 0) {
        return parts[parts.length - 1];
      }
    }
    return null;
  }

  public static cleani18nKey(fullKey: string): string {
    return fullKey.replace('REST_RESPONSE.ERROR.', '');
  }

  public static getNameInitials(name: string, keepFirst?: boolean): string {
    let ret: string = '';
    if (name) {
      const parts: Array<string> = name.split(' ');
      let index: number = 0;
      for (const part of parts) {
        if (part) {
          if (index == 0 && keepFirst) {
            ret += part + ' ';
          } else if (part.length <= 2) {
            ret += part + ' ';
          } else {
            ret += part.substr(0, 1) + '. ';
          }
        }
        index++;
      }
    }
    return ret.trim().replace('  ', ' ');
  }

  public static ellipsisText(pstr: string, max: number, splitFromSpaceChars: boolean): string {
    if (!pstr) {
      return pstr;
    } else {
      const str: string = splitFromSpaceChars ? pstr.split(' ')[0] : pstr;
      return str.substr(0, max) + (str.length > max ? '...' : '');
    }
  }

  public static replaceDoubleSlash(input: string): string {
    return input.replace('//', '/');
  }

  public static refreshView(changeDetectorRef: ChangeDetectorRef): void {
    if (changeDetectorRef) {
      try {
        if (!changeDetectorRef['destroyed']) {
          changeDetectorRef.detectChanges();
        }
      } catch (e) {
        console.error('Warning: Error trying refreshing view in component: ', e);
      }
    } else {
      console.warn('ChangeDetectorRef is empty');
    }
  }

  /*
  * It some cases the FlexLayout directives are not working. One of affected is the AnnouncementBar component.
  */
  public static isGtXS(): boolean {
    return window.innerWidth >= 600;
  }

  public static getMaxNumberFromDigits(digits: number): number {
    return Math.pow(10, digits) - 1;
  }

  public static removeEveryExceptFirst(arr: any[], fieldName1: string, fieldValue1: string, fieldName2: string, fieldValue2: string, minimumNumber: number): number {
    let total: number = 0;
    for (let i = arr.length - 1; i >= 0; i--) {
      const item = arr[i];
      if (item[fieldName1] == fieldValue1 && item[fieldName2] === fieldValue2) {
        total++;
        if (total > minimumNumber) {
          arr.splice(i, 1);
        }
      }
    }
    return total;
  }

  public static removeEvery(arr: any[], fieldName1: string, fieldValue1: string): void {
    for (let i = arr.length - 1; i >= 0; i--) {
      const item = arr[i];
      if (item[fieldName1] == fieldValue1) {
        arr.splice(i, 1);
      }
    }
  }

  public static removeWithProperty<T>(arr: Array<T>, fieldName1: string): void {
    for (let i = arr.length - 1; i >= 0; i--) {
      const item = arr[i];
      if (item[fieldName1] != null) {
        arr.splice(i, 1);
      }
    }
  }

  public static removeWithoutProperty<T>(arr: Array<T>, fieldName1: string): void {
    for (let i = arr.length - 1; i >= 0; i--) {
      const item = arr[i];
      if (item[fieldName1] == null) {
        arr.splice(i, 1);
      }
    }
  }

  public static getNonEmptyArray<T>(array: Array<T>): Array<T> {
    if (!array) {
      return [];
    } else {
      return array;
    }
  }

  /*public static loadHiddenIframe(url: string, accessToken: string, delay: number, delayToRemove: number, debug?: boolean): void {
   setTimeout(() => {
     const ifrm = document.createElement('iframe');
     ifrm.style.position = 'absolute';
     ifrm.style.top = '0';
     ifrm.style.left = '0';
     ifrm.style.border = 'hidden';
     if (debug) {
       ifrm.style.display = 'block';
       ifrm.style.width = '640px';
       ifrm.style.height = '480px';
       ifrm.style.zIndex = '9999';
       ifrm.style.background = '#dfdfdf';
     } else {
       ifrm.style.display = 'none';
       ifrm.style.width = '0';
       ifrm.style.height = '0';
       ifrm.style.zIndex = '-9999';
     }
     ifrm.src = url
     document.body.appendChild(ifrm);
     setTimeout(() => {
       document.body.removeChild(ifrm);
     }, delayToRemove)
   }, delay);
 }*/

 public static async openHiddenPopup(url: string, accessToken: string, delay: number, delayToRemove: number, debug?: boolean): Promise<void> {
    console.warn(accessToken);
     // Change this to use your HTTP client
     fetch(url, {/*YOUR CUSTOM HEADER*/}) // FETCH BLOB FROM IT
       .then((response) => response.blob())
       .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
         const ourl = window.URL.createObjectURL(blob);
         window.open(ourl, '_blank').focus(); // window.open + focus
       }).catch((err) => {
       console.log(err);
     });
 }

  public static getMillisecondsUntilMidnight() {
    const midnight = new Date();
    midnight.setHours( 24 );
    midnight.setMinutes( 0 );
    midnight.setSeconds( 0 );
    midnight.setMilliseconds( 0 );
    return (midnight.getTime() - new Date().getTime());
  }

  public static removeDuplicates<T>(arr: Array<T>, idField: string): Array<T> {
    return arr.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t[idField] === value[idField]
        ))
    )
  }

  public static getHumanBytes(pbytes: number|string): string {
    const DIGIT_NUMBER = 1;
    if (!pbytes) {
      return '';
    }
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'RB', 'QB'];
    const bytes = +(pbytes + '')
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    let value = (bytes / Math.pow(1024, i)).toFixed(DIGIT_NUMBER);

    const parts = value.split('.');
    if (sizes[i] == 'Bytes' && parts.length > 1 && +parts[1] == 0) {
      value = parts[0];
    }

    return `${value} ${sizes[i]}`;
  }

  public static getKeysWithCommonValues<T>(jsonData: { [key: string]: string }, setLowerKeys: boolean, setLowerValues: boolean): { [key: string]: string } {
    const dictionary: { [key: string]: string } = {};
    for (const [key, value] of Object.entries(jsonData)) {
      const ckey: string = (setLowerKeys ? key.toLowerCase() : key);
      const cvalue: string = (setLowerValues ? value.toLowerCase() : value);
      if (!dictionary[cvalue]) {
        dictionary[cvalue] = ckey;
      } else {
        dictionary[cvalue] += ', ' + ckey;
      }
    }
    return dictionary;
  }

  public static replaceAll(text: string, target: string, replacement: string): string {
    return text.split(target).join(replacement);
  }

  public static translateBackendMsg(template: string, arr: string[]): string {
    let ret: string = template;
    for (let i = 0; i < arr.length; i++) {
      ret = Utils.replaceAll(ret, '{' + i + '}', arr[i]);
    }
    return ret;
  }

  public static createStyle(elementRef: ElementRef, renderer: Renderer2, style: string): HTMLStyleElement {
    const styleElement: HTMLStyleElement = renderer.createElement('style');
    renderer.appendChild(
      styleElement,
      document.createTextNode(style)
    );
    renderer.appendChild(elementRef.nativeElement, styleElement);
    return styleElement;
  }

  public static isMobile(deviceService: DeviceDetectorService): boolean {
    return deviceService.isMobile();
  }

  public static isTablet(deviceService: DeviceDetectorService): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
    return isTablet || deviceService.isTablet() || Utils.getiPadModel() != '';
  }

  private static getiPadModel(): string {
    try {
      // Create a canvas element which can be used to retreive information about the GPU.
      var canvas = document.createElement('canvas');
      if (canvas) {
        var context = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        if (context) {
          // @ts-ignore
          var info = context.getExtension('WEBGL_debug_renderer_info');
          if (info) {
            // @ts-ignore
            var renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
          }
        }
      }

      if (window.screen.height / window.screen.width == 1024 / 768) {
        // iPad, iPad 2, iPad Mini
        if (window.devicePixelRatio == 1) {
          switch (renderer) {
            default:
              return 'iPad, iPad 2, iPad Mini';
            case 'PowerVR SGX 535':
              return 'iPad';
            case 'PowerVR SGX 543':
              return 'iPad 2 or Mini';
          }
          // iPad 3, 4, 5, Mini 2, Mini 3, Mini 4, Air, Air 2
        } else {
          switch (renderer) {
            default:
              return 'iPad 3, 4, 5, Mini 2, Mini 3, Mini 4, Air, Air 2';
            case 'PowerVR SGX 543':
              return 'iPad 3';
            case 'PowerVR SGX 554':
              return 'iPad 4';
            case 'Apple A7 GPU':
              return 'iPad Air, Mini 2, Mini 3';
            case 'Apple A8X GPU':
              return 'iPad Air 2';
            case 'Apple A8 GPU':
              return 'iPad Mini 4';
            case 'Apple A9 GPU':
              return 'iPad 5, Pro 9.7';
          }
        }
        // iPad Pro 10.5
      } else if (window.screen.height / window.screen.width == 1112 / 834) {
        return 'iPad Pro 10.5';
        // iPad Pro 12.9, Pro 12.9 (2nd Gen)
      } else if (window.screen.height / window.screen.width == 1366 / 1024) {
        switch (renderer) {
          default:
            return 'iPad Pro 12.9, Pro 12.9 (2nd Gen)';
          case 'Apple A10X GPU':
            return 'iPad Pro 12.9 (2nd Gen)';
          case 'Apple A9 GPU':
            return 'iPad Pro 12.9';
        }
      } else {
        return 'Not an iPad';
      }
    } catch (e) {
    }
    return '';
  }
}

